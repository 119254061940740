<template>
  <show-item-component endpoint="wows"/>
</template>

<script>
export default {
  name: "Show"
}
</script>

<style scoped>

</style>